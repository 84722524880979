<template>
  <div class="projectDetail">
    <div class="projectDetail_top">
      <div class="projectDetail_top_bg1"></div>
      <div class="projectDetail_top_bg2"></div>
      <div class="projectDetail_top_area">
        <div class="projectDetail_top_projectName">中峪道地药材平顶山仿野生白术种植基地</div>
        <div class="projectDetail_top_area_unit">
          项目单位：浙江中峪道地药材有限公司
        </div>
        <div class="projectDetail_top_area_projectPrincipal" style="margin-top: 30px">
          <div>种植面积：</div>
          <div>100亩</div>
          <div class="duangang">|</div>
          <div>种植密度：</div>
          <div>6000株/亩左右</div>
          <div class="duangang">|</div>
          <div>合同年限：2023年8月1日起 - 2043年7月31日</div>
        </div>
        <div class="projectDetail_top_area_line"></div>
        <div class="projectDetail_top_area_projectDesc">
          新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时交付市场。基地在浙江中峪控股集团的高度引领下,由农业版块专业项目负责人统筹科学管理;农科院、农技站等白术种植专家常年驻扎指导基地技术工作;并聘请当地有经验的老百姓进行种植养护工作。全方面保障中峪道地药材平顶山项目基地的先进样板落地闭环。浙江中峪道地药材有限公司已经被评为新昌沃洲重点农业企业,并被授予第一批“共富工坊”荣誉称号。
        </div>
        <div class="projectDetail_top_area_download">
          <img class="moveTop" src="../../static/newImg/yuchan.png" width="240px" height="173px"
            style="cursor: pointer;" @click="changeReport" />
          <el-image class="moveTop" style="width: 240px; height: 173px"
            src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/55f321783aef4980933186cbfff1c4e3.png"></el-image>
          <img class="moveTop" src="../../static/newImg/cunchu.png" width="240px" height="173px"
            style="cursor: pointer;" @click="changeSite" />
        </div>
      </div>
      <div class="projectDetail_bottom_title" id="projectDetail_bottom_title">
        <div class="projectDetail_bottom_title_outSide">
          <div class="projectDetail_bottom_title_item" :class="activeIndex == 0 ? ' active' : ''"
            @click="setTopAndIndex(0)">
            基地详情</div>
          <div class="projectDetail_bottom_title_item" :class="activeIndex == 1 ? ' active' : ''"
            @click="setTopAndIndex(1)">
            项目土地</div>
          <div class="projectDetail_bottom_title_item" :class="activeIndex == 2 ? ' active' : ''"
            @click="setTopAndIndex(2)">
            产品介绍</div>
          <div class="projectDetail_bottom_title_item" :class="activeIndex == 3 ? ' active' : ''"
            @click="setTopAndIndex(3)">
            项目进度 <img class="projectDetail_bottom_title_item_new" v-show="showProgessTips"
              src="../../static/newImg/new.png" width="30px" />
          </div>
          <div class="projectDetail_bottom_title_item" :class="activeIndex == 4 ? ' active' : ''"
            @click="setTopAndIndex(4)">
            项目动态<img class="projectDetail_bottom_title_item_new" v-show="showTrendTips"
              src="../../static/newImg/new.png" width="30px" /></div>
        </div>
      </div>
    </div>
    <!-- 基地详情 -->
    <div class="projectDetail_productPresentation_base">
      <div class="projectDetail_productPresentation_base_content">
        <div class="productPresentation_top_area">
          <div class="productPresentation_head_content_title_right">
            <img src="../../static/newImg/Ricon.png" alt="" />
          </div>
          基地详情
          <div class="productPresentation_head_content_title_left">
            <img src="../../static/newImg/Licon.png" alt="" />
          </div>
        </div>
        <div class="ProjectBase">
          <div style="">责任主体：浙江中峪道地药材有限公司</div>
          <div class="ProjectBase_punctuation">/</div>
          <div style="">负责人员：陈*英 </div>
          <div class="ProjectBase_punctuation">/</div>
          <div>工作人员：黄坛村集体 &nbsp;</div>
        </div>
        <div class="ProjectBase" style="">
          <div style="">村集体 ： 浙江新昌县沃洲镇黄坛村</div>
          <div class="ProjectBase_punctuation">/</div>
          <div style="">收成时间：2025年10月 - 2026年1月</div>
          <div class="ProjectBase_punctuation">/</div>
          <div>巡检人员：陈*英 139****6885</div>
        </div>
        <div class="ProjectBase1" style="">
          <div style="margin-top: 88px;">预测产量：
            <el-image style="width: 240px;vertical-align: bottom;margin-right: 5px;"
              :src="require('../../static/newImg/img82.png')" class="ProjectBase1Image"
              :preview-src-list="srcList1"></el-image>
          </div>
          <div style="margin-top: 88px;margin-left: 40px;">预估产值：根据2023年市场价评估 3.5万~4.5万元/亩 &nbsp;</div>
        </div>
        <el-carousel class="el-carousel_head_content_title_left imgList1" ref="imgList1" :interval="5000" arrow="always"
          :indicator-position="imglist1.length == 1 ? 'none' : 'inside'" :autoplay="false" height="600px">
          <el-carousel-item v-for="(item, index) in imglist1" :key="item">
            <div style="display: flex; height: 100%; justify-content: center">
              <div class="carousel_left">
                <div class="carousel_left_bg"></div>
                <img style="width: 1200px;" :src="item" v-if="item.indexOf('.mp4') == -1"
                  :style="imglist1.length == 1 ? 'transform: translate(0, -20%);' : ''" />
                <video :poster="imglist1[1]" :id="'videoPlayer' + index" :src="item" v-if="item.indexOf('.mp4') > 0"
                  class="video-js"></video>
                <!-- <video :poster="imglist1[1]" :id="'videoPlayer' + index" :src="item" controls preload="auto"
                  v-if="item.indexOf('.mp4') > 0 && deviceType == 'IOS'"></video> -->
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="projectDetail_head_content_resource_head">
          <div class="swiper-container2">
            <div class="swiper-wrapper">
              <div class="swiper-slide projectDetail_head_content_resource_head_slide" v-for="(item, index) in 3"
                :key="index">
                <div
                  style="cursor: pointer;text-shadow: 8px 13px 12px rgba(0,0,0,0.31);font-weight: bold;letter-spacing: 2px;"
                  v-if="index == 0" @click="changeImgList1(0)">种植期</div>
                <div
                  style="cursor: pointer;text-shadow: 8px 13px 12px rgba(0,0,0,0.31);font-weight: bold;letter-spacing: 2px"
                  v-if="index == 1" @click="changeImgList1(1)">维护期</div>
                <div
                  style="cursor: pointer;text-shadow: 8px 13px 12px rgba(0,0,0,0.31);font-weight: bold;letter-spacing: 2px"
                  v-if="index == 2" @click="changeImgList1(2)">丰收期</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 项目土地 -->
    <div class="projectDetail_area">
      <div class="projectDetail_area_contentDiv">
        <div class="projectDetail_area_title">
          <div class="projectDetail_area_title_left">
            <img src="../../static/newImg/colorRicon.png" alt="" />
          </div>
          项目土地
          <div class="projectDetail_area_title_right">
            <img src="../../static/newImg/colorLicon.png" alt="" />
          </div>
        </div>
        <div class="projectDetail_area_contentDiv_outside">
          <el-carousel class="imgList2" ref="imgList2" :interval="5000" arrow="always" :autoplay="false" height="776px">
            <el-carousel-item v-for="(item, index) in imgLandList" :key="item">
              <div style="display: flex; height: 100%; justify-content: center">
                <div class="carousel_left">
                  <div class="projectDetail_area_contentDiv_outside_bg"></div>
                  <img style="width: 1200px;" :src="item" v-if="item.indexOf('.mp4') == -1" />
                  <video class="video-js" style="height: 100%;" :src="item" v-if="item.indexOf('.mp4') > 0"></video>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <div class="projectDetail_area_contentDiv_outside_tips">
            <div class="projectDetail_area_contentDiv_outside_tips_title">土地编号 ZJSX0575XCWZHT001SFYS </div>
            <div class="projectDetail_area_contentDiv_outside_tips_collect">
              <div class="projectDetail_area_contentDiv_outside_tips_collect_htnx">合同年限：2023年8月 - 2043年7月
                <br>种植时间：2023年12月 - 2024年2月
              </div>
              <div class="projectDetail_area_contentDiv_outside_tips_collect_csrq">
                收成时间：2025年10月 - 2026年1月<br>土地地点：新昌县沃洲镇黄坛村平顶山自然村
              </div>
              <!-- <div class="projectDetail_area_contentDiv_outside_tips_collect_csrq">
                交割日期：2026年1月
              </div> -->
              <!-- <div class="projectDetail_area_contentDiv_outside_tips_collect_more">
                <img src="../../static/newImg/img93.png" width="54px" />
              </div> -->
            </div>
          </div>
        </div>
        <!-- 2024-04-23 隐藏 -->
        <!-- <div class="projectDetail_area_contentDiv_list">
          <div class="swiper-containerland">
            <div class="swiper-wrapper">
              <div class="swiper-slide projectDetail_area_contentDiv_list_item" v-for="(item, index) in 4" :key="item"
                @click="changeImageAndVideoIndex(index)">
                <div class="projectDetail_area_contentDiv_list_item_outside"
                  :class="index == ImageAndVideoIndex ? ' active' : ''">
                  <img :src="imgLandList[index]" width="385px" />
                  <div
                    style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;background-color: transparent;">
                  </div>
                </div>
                <div class="projectDetail_area_contentDiv_list_item_tips">土地编号 838478299171929{{ item }}</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="projectDetail_productPresentation_projectBrief">
      <div class="projectDetail_productPresentation_projectBrief_content">
        <div class="productPresentation_top_area">
          <div class="productPresentation_head_content_title_right">
            <img src="../../static/newImg/Ricon.png" alt="" />
          </div>
          产品介绍
          <div class="productPresentation_head_content_title_left">
            <img src="../../static/newImg/Licon.png" alt="" />
          </div>
        </div>
        <div class="projectDetail_productPresentation_projectBrief_content_outside">
          <div class="projectDetail_productPresentation_projectBrief_content_left" @click="changeContainer5(-1)">
            <img src="../../static/newImg/img84.png" width="60px" />
          </div>
          <div class="projectDetail_productPresentation_projectBrief_content_list">
            <el-carousel height="400px" indicator-position="none" ref="products" :interval="8000">
              <el-carousel-item v-for="(item, index) in productList" :key="index + 'products'"
                class="projectDetail_productPresentation_projectBrief_list">
                <div class="projectDetail_productPresentation_projectBrief_list_item_img">
                  <div class="swiper-container5" style="height: 100%;">
                    <div class="swiper-wrapper" style="height: 100%;">
                      <div class="swiper-slide" v-for="(img, index) in item.img" :key="index + 'img'"
                        style="height: 100%;">
                        <img :src="img" height="100%" width="100%" />
                      </div>
                    </div>
                  </div>
                  <div class="productList_bg" v-show="item.img.length > 1">
                    <div class="productList_bg_item" v-for="(page, pageIndex) in item.img" :key="page + 'page'"
                      :class="pageIndex == productsActiveIndex ? ' active' : ''">
                      <div class="productList_bg_item_point"></div>
                    </div>
                  </div>
                </div>
                <div class="projectDetail_productPresentation_projectBrief_list_item_intro" v-if="container5">
                  <div class="projectDetail_productPresentation_projectBrief_list_item_intro_title">
                    <span style="font-weight: bold;">{{ item.name }} </span>
                  </div>
                  <div class="projectDetail_productPresentation_projectBrief_list_item_intro_tips">
                    {{ item.tips }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>

          <!-- <div class="swiper-container5 projectDetail_productPresentation_projectBrief_content_list">
            <div class="swiper-wrapper">
              <div class="swiper-slide projectDetail_productPresentation_projectBrief_list"
                v-for="(item, index) in productList" :key="index">
                <div class="projectDetail_productPresentation_projectBrief_list_item_img">
                  <el-carousel height="400px" class="imgList4" ref="imgList4">
                    <el-carousel-item v-for="(img, index) in item.img" :key="index + 'img'">
                      <img :src="img" height="100%" width="100%" />
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="projectDetail_productPresentation_projectBrief_list_item_intro">
                  <div class="projectDetail_productPresentation_projectBrief_list_item_intro_title">
                    <span style="font-weight: bold;">{{ item.name }} </span>
                  </div>
                  <div class="projectDetail_productPresentation_projectBrief_list_item_intro_tips">
                    {{ item.tips }}
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="projectDetail_productPresentation_projectBrief_content_right" @click="changeContainer5(1)">
            <img src="../../static/newImg/img83.png" width="60px" />
          </div>
        </div>
      </div>
    </div>
    <div class="projectDetail_productPresentation_Schedule">
      <div class="projectDetail_productPresentation_Schedule_content">
        <div class="productPresentation_top_area_Schedule">
          <div class="productPresentation_head_content_title_right">
            <img src="../../static/newImg/Ricon.png" alt="" />
          </div>
          项目进度
          <div class="productPresentation_head_content_title_left">
            <img src="../../static/newImg/Licon.png" alt="" />
          </div>
        </div>
        <div class="projectDetail_productPresentation_Schedule_content_line">
          <div class="projectDetail_productPresentation_Schedule_content_line_1"></div>
          <div class="swiper-container3">
            <div class="swiper-wrapper">
              <div class="swiper-slide"></div>
              <div class="swiper-slide projectDetail_productPresentation_Schedule_content_line_item"
                @click="showScheduleIndex(index)" v-for="(item, index) in scheduleList" :key="index"
                :class="(scheduleIndex == index ? ' active' : '')">
                <div class="projectDetail_productPresentation_Schedule_content_line_item_date">{{ item.progressDate }}
                </div>
                <div class="projectDetail_productPresentation_Schedule_content_line_item_date">{{ item.progressTitle }}
                </div>
                <div class="projectDetail_productPresentation_Schedule_content_line_item_circle"></div>
                <div class="projectDetail_productPresentation_Schedule_content_line_item_activeIcon">
                  <img src="../../static/newImg/img94.png" width="16px" />
                </div>
                <div class="projectDetail_productPresentation_Schedule_content_line_item_name"> {{ item.progressSchedule
                  }} </div>
                <div class="projectDetail_productPresentation_Schedule_content_line_item_icon"></div>
              </div>
              <div class="swiper-slide"></div>
            </div>
          </div>
        </div>
        <div class="projectDetail_productPresentation_Schedule_content_outside">
          <div class="projectDetail_productPresentation_Schedule_content_left" @click="changeContainer3(-1)"
            :class="scheduleIndex == 0 ? 'showBtn' : ''">
            <img src="../../static/newImg/img84.png" width="60px" />
          </div>
          <div class="projectDetail_productPresentation_Schedule_content_list">
            <div class="projectDetail_productPresentation_Schedule_content_list_img">
              <el-carousel class="imgList3" ref="imgList3" :interval="5000" arrow="always"
                :indicator-position="imgList3.length == 1 ? 'none' : 'inside'" :autoplay="false" height="326px">
                <el-carousel-item v-for="(item, index) in imgList3" :key="item">
                  <div style="display: flex; height: 100%; justify-content: center;width:500px;overflow: hidden;">
                    <div class="carousel_left_bg"></div>
                    <img style="width: 100%;height: 100%;" :src="item + imgSuffix" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="projectDetail_productPresentation_Schedule_content_list_content" v-if="scheduleList.length">
              <div class="projectDetail_productPresentation_Schedule_content_list_content_nav"></div>
              <div class="projectDetail_productPresentation_Schedule_content_list_content_tips">
                {{ scheduleList[scheduleIndex].progressContent }}
              </div>
              <div class="projectDetail_productPresentation_Schedule_content_list_content_file"
                @click="downloadFile(scheduleList[scheduleIndex].progressShowFile)"
                v-show="scheduleList[scheduleIndex].progressShowFile">
                {{ scheduleList[scheduleIndex].progressTitle }}<i class="el-icon-arrow-right"
                  style="font-size: 16px;"></i>
              </div>
              <div class="projectDetail_productPresentation_Schedule_content_list_content_date">
                更新日期 {{ scheduleList[scheduleIndex].progressDate }}
              </div>
              <!-- <div class="projectDetail_productPresentation_Schedule_content_list_content_more">
              <i class="el-icon-arrow-right"></i>
            </div> -->
            </div>
          </div>
          <div class="projectDetail_productPresentation_Schedule_content_right" @click="changeContainer3(1)"
            :class="scheduleIndex == (scheduleList.length - 1) ? 'showBtn' : ''">
            <img src="../../static/newImg/img83.png" width="60px" />
          </div>
        </div>
        <!-- <div class="projectDetail_productPresentation_Schedule_content_more">
          查看更多详情 <i class="el-icon-arrow-right"></i>
        </div> -->
      </div>
    </div>
    <div class="projectDetail_dynamic">
      <div class="projectDetail_dynamic_title">
        <div class="projectDetail_dynamic_title_left">
          <img src="../../static/newImg/colorRicon.png" alt="" />
        </div>
        项目动态
        <div class="projectDetail_dynamic_title_right">
          <img src="../../static/newImg/colorLicon.png" alt="" />
        </div>
      </div>
      <div class="projectDetail_dynamic_list">
        <div class="projectDetail_dynamic_list_item" v-for="item in trendsList" :key="item.trendsId">
          <img src="../../static/newImg/projectDetail_bg3.png" width="62px" />
          <div class="projectDetail_dynamic_list_time">
            更新日期 {{ item.trendsDate }}
          </div>
          <div class="projectDetail_dynamic_list_line">
            <div class="projectDetail_dynamic_list_line_inside">
              <div class="projectDetail_dynamic_list_line_bg">
                <div class="projectDetail_dynamic_list_line_name">
                  巡检人员 {{ item.inspector }}
                </div>
                <div class="projectDetail_dynamic_list_line_content">
                  {{ item.trendsTitle }}
                </div>
                <div class="projectDetail_dynamic_list_line_img">
                  <el-image style="width: 31%;height: 266px;" v-for="imgItem in item.imgList" :key="imgItem"
                    :src="imgItem + imgSuffix" :preview-src-list="item.imgList"></el-image>
                </div>
                <div class="projectDetail_dynamic_list_line_tips">
                  #{{ item.trendsTagName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="projectDetail_dynamic_list_more" @click="getMore()" v-show="showMore">
          点击查看更多
        </div>
      </div>
    </div>
    <el-dialog title="" class="site_dialog_outside" :visible.sync="siteDialogVisible" :width="siteDialogWidth" center
      :append-to-body="true" top="50vh">
      <div class="site_Dialog">
        <div class="site_Dialog_item" v-for="item in siteList" :key="item.name + 'site'">
          <div class="site_Dialog_item_img">
            <el-image style="width: 85%;height: 100%;" :src="require('../../static/newImg/dashedMap1.png')"
              :preview-src-list="[require('../../static/newImg/dashedMap1.png')]"></el-image>
          </div>
          <div class="site_Dialog_item_title">
            <div class="site_Dialog_item_title_label"><span>站点名：</span>{{ item.name }}</div>
            <div class="site_Dialog_item_title_label"><span>详细地址：</span>{{ item.position }}</div>
            <div class="site_Dialog_item_title_label"><span>联系人：</span>{{ item.name1 }}</div>
            <div class="site_Dialog_item_title_label"><span>联系电话：</span>{{ item.tel }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" class="report_dialog_outside" :visible.sync="reportDialogVisible" :width="reportDialogWidth"
      center :append-to-body="true" top="50vh">
      <div class="report_Dialog">
        <div class="report_Dialog_item" v-for="(item, index) in reportList" :key="item.id + 'report'">
          <div class="report_Dialog_item_img">
            <el-image style="width: 100%;" :src="item.img" :preview-src-list="srcList1"></el-image>
          </div>
          <div class="report_Dialog_item_title">
            <div class="report_Dialog_item_title_label"><span>预测产量：</span>{{ item.num }}</div>
            <div class="report_Dialog_item_title_label"><span>预估产值：</span>{{ item.value }}</div>
            <div class="report_Dialog_item_title_label"><span>种植密度：</span>{{ item.value1 }}</div>
            <div class="report_Dialog_item_title_label"><span>收成时间：</span>{{ item.value2 }}</div>
            <div class="report_Dialog_item_title_label"><span>评估日期：</span>{{ item.date }}</div>
            <div class="report_Dialog_item_title_more" @click="triggerClickImgList('reportImgList1')">查看详情<i
                class="el-icon-arrow-right" style="font-size: 16px;"></i></div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- <div class="report_dialog" v-show="reportDialogVisible" @click="invalidClick($event)">
      <div class="report_dialog_outside">
        <div class="report_dialog_inside">
          <div class="report_dialog_inside_item">
            <el-image style="width: 100%;" :src="require('../../static/newImg/img91.jpg')" :preview-src-list="srcList1"
              ref="reportImgList1"></el-image>
            <div class="report_dialog_inside_item_bg" @click="triggerClickImgList('reportImgList1')">
              点击查看<img src="../../static/newImg/fangda.png" width="16px" />
            </div>
          </div>
          <div class="report_dialog_inside_item">
            <el-image style="width: 100%;" :src="require('../../static/newImg/img92.jpg')" :preview-src-list="srcList1"
              ref="reportImgList2"></el-image>
            <div class="report_dialog_inside_item_bg" @click="triggerClickImgList('reportImgList2')">
              点击查看<img src="../../static/newImg/fangda.png" width="16px" />
            </div>
          </div>
          <div class="report_dialog_inside_item">
            <el-image style="width: 100%;" :src="require('../../static/newImg/img91.jpg')" :preview-src-list="srcList1"
              ref="reportImgList3"></el-image>
            <div class="report_dialog_inside_item_bg" @click="triggerClickImgList('reportImgList3')">
              点击查看<img src="../../static/newImg/fangda.png" width="16px" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div>
      <el-image style="display: none;" :src="require('../../static/newImg/img91.jpg')" :preview-src-list="srcList1"
        ref="reportImgList1"></el-image>
    </div>
  </div>
</template>
<script>
import { Swiper } from "swiper";
import { getPojectDetail, getTrends, getProgress, getSignature } from "@/api/index.js";
import { set } from "core-js/core/dict";
export default {
  name: "projectDetail",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: '?x-oss-process=image/resize,m_lfit,w_300',
      projectDesc: '新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时交付市场。基地在浙江中峪控股集团的高度引领下,由农业版块专业项目负责人统筹科学管理;农科院、农技站等白术种植专家常年驻扎指导基地技术工作;并聘请当地有经验的老百姓进行种植养护工作。全方面保障中峪道地药材平顶山项目基地的先进样板落地闭环。浙江中峪道地药材有限公司已经被评为新昌沃洲重点农业企业,并被授予第一批“共富工坊”荣誉称号。',
      siteDialogWidth: '1000px',
      siteDialogVisible: false,
      reportDialogWidth: '1000px',
      reportDialogVisible: false,
      styId: 0,
      projectId: 0,
      projectInfo: {
        base: {},
        landDTOList: {},
        productDTOList: {},
        projectDTO: {},
      },
      imgAndVideo: [],
      container1: null,
      container1Index: 0,
      current: 1,
      trendsList: [],
      myPlayer4: null,
      myPlayer5: null,
      container2: null,
      container3: null,
      container4: null,
      container5: null,
      showMore: true,
      listItem: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      container: 0,
      activeIndex: 0,
      ImageAndVideoIndex: 0,
      showProgessTips: true,
      showTrendTips: true,
      showProgessDate: '',
      showTrendDate: '',
      containerland: null,
      imglist1: [
        require('../../static/newImg/whq1.jpg'),
        require('../../static/newImg/whq2.jpg'),
        require('../../static/newImg/whq3.jpg'),
        require('../../static/newImg/whq4.jpg'),
        require('../../static/newImg/whq5.mp4'),
        require('../../static/newImg/whq6.mp4'),
        require('../../static/newImg/whq7.jpg'),
      ],
      imgList2: [
        require('../../static/newImg/img95.jpg'),
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/a0f6fe32e04347b09777330e5dabc153.jpg",
      ],
      imgLandList: [
        require('../../static/newImg/img95.jpg'),
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/a0f6fe32e04347b09777330e5dabc153.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/612ed47a3c7d43a0bac29ec92c3f241b.jpg",
        require('../../static/newImg/img98.jpg'),
        require('../../static/newImg/img99.jpg'),
      ],
      scheduleIndex: 0,
      scheduleList: [],
      imgList3: [
      ],
      deviceType: '',
      srcList1: [
        require('../../static/newImg/img91.jpg'),
        require('../../static/newImg/img92.jpg'),
      ],
      srcList2: [
        require('../../static/newImg/img90.jpg'),
      ],
      srcList3: [
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/85742d756a1a4ba3b4d7437a3d9bc5e4.jpg",
      ],
      productList: [
        {
          tips: '白术喜凉爽、温和气候，耐寒、耐旱，忌积水。最适宜生长温度15~22℃，以24~29℃生长迅速。根茎生长最适温度26~28℃。种子发芽最适温度25~30℃。对土壤要求较低，以选地势高燥稍有倾斜的坡地，土层深厚，疏松肥沃，排水良好的砂质壤土栽培为宜，忌连作，最好在新垦地上栽种。',
          name: '白术种植期',
          no: '2938473992',
          img: [require('../../static/newImg/img88.jpg'), require('../../static/newImg/img87.jpg')]
        },
        {
          tips: '白术茎叶开始枯萎时为采收适期。采收时，挖出根茎，剪去茎秆，运回加工。烘干时，开初用猛火，温度可掌握在90-100℃之间，出现水汽时降温至60-70℃，2-3小时上下翻动一次，须根干燥时取出闷堆“发汗”7-10天，再烘至全干，并将残茎和须根搓去。产品以个大肉厚、无高脚茎、无须根、坚固不空心、断面色黄白、香气浓郁者为佳。',
          name: '白术成熟期',
          no: '2938473992',
          img: [require('../../static/newImg/img86.jpg'), require('../../static/newImg/img85.jpg')]
        },
        {
          tips: '烘术的断面淡黄白色，角质，中央时有裂隙。生晒术的断面皮部类白色，本质部淡黄色至黄色，有油点。气香，味甜微辛，略带粘液性。以个大、表面灰黄色、断面黄白色、有云头、质坚实、无空心者为佳。',
          name: '风干白术',
          no: '2938473992',
          img: [require('../../static/image/introduction_4.png')]
        },
      ],
      productsActiveIndex: 0,
      siteList: [
        {
          name: '新昌县沃洲镇黄坛站点',
          position: '新昌县沃洲镇黄坛村委大楼',
          name1: '赵*萍',
          tel: '188****5413'
        },
      ],
      reportList: [
        {
          id: 0,
          img: require('../../static/newImg/img92.jpg'),
          date: '2024年1月1号',
          num: '鲜白术 900~1200公斤/亩(干白术250~300公斤/亩)',
          value: '根据2023年市场价评估 3.5万~4.5万元/亩',
          value1: '6000株/亩左右',
          value2: '2025年10月~2026年1月'
        },
      ]
    };
  },
  watch: {
    reportDialogVisible(val) {
      if (val) {
        document.body.style = "overflow: hidden;"
        const report_dialog = document.getElementsByClassName('report_dialog')[0]
        if (report_dialog) {
          document.body.append(report_dialog)
        }

      } else {
        document.body.style = ""
        const report_dialog = document.getElementsByClassName('report_dialog')[0]
        if (report_dialog) {
          document.getElementsByClassName('projectDetail')[0].append(report_dialog)
        }
      }
    }
  },
  filters: {
    fomatterDate: (val) => {
      if (val.length > 11) {
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val = val.replace(",", "日 - ");
        val += "日";
      } else {
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val += "日";
      }
      return val;
    },
  },
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = ''
    }, 4000)
    window.scroll(0, 0);
    this.projectId = this.$route.query.id;
    this.getPojectDetail();
    this.getTrends(1);
    // this.scrollToVisible(this.styId);
    const that = this
    this.containerland = new Swiper(".swiper-containerland", {
      slidesPerView: 'auto',
      spaceBetween: 24,
    });
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.deviceType = 'IOS'
      window.addEventListener("scroll", this.changeTips)
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.deviceType = 'Android'
      window.addEventListener("scroll", this.changeTips)
    } else {
      window.addEventListener("scroll", this.changeTitle)
    }
    if (this.$refs.imgList1) {
      this.slideBanner1()
    }
    if (this.$refs.imgList2) {
      this.slideBanner2()
    }
    if (this.$refs.imgList3) {
      this.slideBanner3()
    }
    if (this.$refs.imgList4) {
      this.slideBanner4()
    }
    this.container4 = new Swiper(".swiper-container4", {
      slidesPerView: 1,
      observer: true, //开启动态检查器，监测swiper和slide
      observeParents: true, //监测Swiper 的祖/父元素
    });
    this.container5 = new Swiper(".swiper-container5", {
      slidesPerView: 1,
      observer: true, //开启动态检查器，监测swiper和slide
      observeParents: true, //监测Swiper 的祖/父元素
      on: {
        slideChange: function () {
          that.productsActiveIndex = this.activeIndex
        },
      },
    });
    this.getScheduleList()
    this.getWXInfo()
  },
  methods: {
    getWXInfo() {
      const that = this
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪道地药材平顶山仿野生白术种植基地', // 分享标题
            desc: '新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时交付市场。基地在浙江中峪控股集团的高度引领下,由农业版块专业项目负责人统筹科学管理;农科院、农技站等白术种植专家常年驻扎指导基地技术工作;并聘请当地有经验的老百姓进行种植养护工作。全方面保障中峪道地药材平顶山项目基地的先进样板落地闭环。浙江中峪道地药材有限公司已经被评为新昌沃洲重点农业企业,并被授予第一批“共富工坊”荣誉称号。', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://www.zoneyu.cc/img/whq1.aa941a7f.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    downloadFile(url) {
      const a = document.createElement('a');
      a.style.display = 'none';
      const name = url.split('/')
      a.download = name[name.length - 1];
      a.href = url;
      a.target = '_blank'
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getScheduleList() {
      getProgress({ projectId: this.projectId, current: 1, size: 1000 }).then((res) => {
        this.scheduleList = res.data.list
        this.scheduleList.forEach((item) => {
          item.img = []
          const img = item.progressImg.split(',')
          img.forEach((val) => {
            if (val) {
              item.img.push(val)
            }
          })
        })
        this.scheduleList = this.scheduleList.reverse()
        this.container3 = new Swiper(".swiper-container3", {
          slidesPerView: 3,
          observer: true, //开启动态检查器，监测swiper和slide
          observeParents: true, //监测Swiper 的祖/父元素
        });
        if (!localStorage.getItem('showProgessTips') || localStorage.getItem('showProgessDate') != this.scheduleList[0].progressDate) {
          this.showProgessTips = true
        } else {
          this.showProgessTips = false
        }
        setTimeout(() => {
          this.container3.slideTo(this.scheduleList.length - 1)
          this.scheduleIndex = this.scheduleList.length - 1
          this.imgList3 = this.scheduleList[this.scheduleList.length - 1].img
        }, 200)
      })
    },
    changeSite() {
      if (window.screen.width < 1280) {
      } else {
        this.siteDialogWidth = '1000px'
      }
      setTimeout(() => {
        this.siteDialogVisible = true
        if (window.screen.width < 1280) {
          document.getElementsByClassName("el-dialog")[0].style =
            "transform: scale(" + window.screen.width / 1280 + ") translate(-50%, -50%);transform-origin: 0 0;position: absolute;top: 50%;left: 50%;width: 200%;";
        }
      }, 400)
    },
    changeReport() {
      if (window.screen.width < 1280) {
      } else {
        this.reportDialogWidth = '1000px'
      }
      setTimeout(() => {
        this.reportDialogVisible = true
        if (window.screen.width < 1280) {
          document.getElementsByClassName("report_dialog_outside")[0].childNodes[0].style =
            "transform: scale(" + window.screen.width / 1280 + ") translate(-50%, -50%);transform-origin: 0 0;position: absolute;top: 50%;left: 50%;width: 200%;";
        }
      }, 400)
    },
    triggerClickImgList(refName) {
      this.$refs[refName].showViewer = true;
    },
    invalidClick(e) {
      if (e.target.className == 'report_dialog_outside' || e.target.className == 'report_dialog') {
        this.reportDialogVisible = false
      }
    },
    changeContainer5(index) {
      this.productsActiveIndex = 0
      if (index == -1) this.$refs.products.prev();
      if (index == 1) this.$refs.products.next();
    },
    changeContainer3(index) {
      if (index == -1) {
        this.container3.slidePrev();
        if (this.scheduleIndex == 0) {
          this.scheduleIndex = 0
          return
        }
      }
      if (index == 1) {
        this.container3.slideNext();
        if (this.scheduleIndex == 4) {
          this.scheduleIndex = 4
          return
        }
      }
      this.scheduleIndex += index
      this.imgList3 = this.scheduleList[this.scheduleIndex].img
      document.getElementsByClassName('projectDetail_productPresentation_Schedule_content_list')[0].className = 'projectDetail_productPresentation_Schedule_content_list moveleft '
      setTimeout(() => {
        document.getElementsByClassName('projectDetail_productPresentation_Schedule_content_list')[0].className = 'projectDetail_productPresentation_Schedule_content_list'
      }, 200)
    },
    setTopAndIndex(index) {
      this.activeIndex = index
      let scale = 1
      if (window.screen.width < 500) {
        scale = window.screen.width / 1240
      }
      if (index == 0) window.scrollTo(0, 1032 * scale)
      if (index == 1) window.scrollTo(0, (1032 + 1400) * scale)
      if (index == 2) window.scrollTo(0, (1032 + 1400 + 1200) * scale)
      if (index == 3) window.scrollTo(0, (1032 + 1400 + 1200 + 820) * scale)
      if (index == 4) window.scrollTo(0, (1032 + 1400 + 1200 + 820 + 1200) * scale)
    },
    changeTitle() {
      const html = document.getElementsByClassName('projectDetail_bottom_title')[0]
      if (window.scrollY > 880) {
        // 避免重复添加
        if (html && html.parentElement.tagName != 'BODY') {
          document.body.append(html)
        }
      } else if (window.scrollY < 800) {
        // 避免重复添加
        if (html && html.parentElement.tagName != 'DIV') {
          document.getElementsByClassName('projectDetail_top')[0].append(html)
        }
      }
      const sheight = window.innerHeight - 300
      if (window.scrollY > (1032 - sheight) && window.scrollY < (1032 + 1400 - sheight)) {
        this.activeIndex = 0
      }
      if (window.scrollY > (1032 + 1400 - sheight) && window.scrollY < (1032 + 1400 + 1200 - sheight)) {
        this.activeIndex = 1
      }
      if (window.scrollY > (1032 + 1400 + 1200 - sheight) && window.scrollY < (1032 + 1400 + 1200 + 820 - sheight)) {
        this.activeIndex = 2
      }
      if (window.scrollY > (1032 + 1400 + 1200 + 820 - sheight) && window.scrollY < (1032 + 1400 + 1200 + 820 + 1200 - sheight)) {
        this.activeIndex = 3
        if (this.showProgessTips) {
          this.showProgessTips = false
          localStorage.setItem('showProgessTips', 'unshow')
          localStorage.setItem('showProgessDate', this.scheduleList[0].progressDate)
        }
      }
      if (window.scrollY > (1032 + 1400 + 1200 + 820 + 1200 - sheight)) {
        this.activeIndex = 4
        if (this.showTrendTips) {
          this.showTrendTips = false
          localStorage.setItem('showTrendTips', 'unshow')
          localStorage.setItem('showTrendDate', this.trendsList[0].trendsDate)
        }
      }
    },
    changeTips() {
      const type1 = this.isElementInViewport(document.getElementsByClassName('productPresentation_top_area_Schedule')[0])
      if (type1) {
        this.showProgessTips = false
        localStorage.setItem('showProgessTips', 'unshow')
        localStorage.setItem('showProgessDate', this.scheduleList[0].progressDate)
      }
      const type2 = this.isElementInViewport(document.getElementsByClassName('projectDetail_dynamic_title')[0])
      if (type2) {
        this.showTrendTips = false
        localStorage.setItem('showTrendTips', 'unshow')
        localStorage.setItem('showTrendDate', this.trendsList[0].trendsDate)
      }
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    showScheduleIndex(index) {
      this.scheduleIndex = index
      this.imgList3 = this.scheduleList[index].img
      this.container3.slideTo(index);
      document.getElementsByClassName('projectDetail_productPresentation_Schedule_content_list')[0].className = 'projectDetail_productPresentation_Schedule_content_list moveleft '
      setTimeout(() => {
        document.getElementsByClassName('projectDetail_productPresentation_Schedule_content_list')[0].className = 'projectDetail_productPresentation_Schedule_content_list'
      }, 200)
    },
    slideBanner1() {
      //选中的轮播图
      var box = document.querySelector('.imgList1 .el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.imgList1.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.imgList1.prev();
          return;
        }
      });
      // 处理鼠标按下
      box.addEventListener('mousedown', function (event) {
        event.preventDefault();
        startPoint = event.pageX;
      });
      // 处理鼠标抬起
      box.addEventListener('mouseup', function (event) {
        event.preventDefault();
        stopPoint = event.pageX;
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.imgList1.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.imgList1.prev();
          return;
        }
      });
    },
    slideBanner2() {
      //选中的轮播图
      var box = document.querySelector('.imgList2 .el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.imgList2.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.imgList2.prev();
          return;
        }
      });
      // 处理鼠标按下
      box.addEventListener('mousedown', function (event) {
        event.preventDefault();
        startPoint = event.pageX;
      });
      // 处理鼠标抬起
      box.addEventListener('mouseup', function (event) {
        event.preventDefault();
        stopPoint = event.pageX;
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.imgList2.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.imgList2.prev();
          return;
        }
      });
    },
    slideBanner3() {
      //选中的轮播图
      var box = document.querySelector('.imgList3 .el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.imgList3.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.imgList3.prev();
          return;
        }
      });
      // 处理鼠标按下
      box.addEventListener('mousedown', function (event) {
        event.preventDefault();
        startPoint = event.pageX;
      });
      // 处理鼠标抬起
      box.addEventListener('mouseup', function (event) {
        event.preventDefault();
        stopPoint = event.pageX;
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.imgList3.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.imgList3.prev();
          return;
        }
      });
    },
    slideBanner4() {
      //选中的轮播图
      var box = document.querySelector('.imgList4 .el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.imgList4.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.imgList4.prev();
          return;
        }
      });
      // 处理鼠标按下
      box.addEventListener('mousedown', function (event) {
        event.preventDefault();
        startPoint = event.pageX;
      });
      // 处理鼠标抬起
      box.addEventListener('mouseup', function (event) {
        event.preventDefault();
        stopPoint = event.pageX;
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.imgList4.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.imgList4.prev();
          return;
        }
      });
    },
    changeImageAndVideoIndex(index) {
      this.ImageAndVideoIndex = index
      this.containerland.slideTo(
        index,
        1000,
        false
      );
      if (index == 1) {
        this.imgList2 = [
          "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/612ed47a3c7d43a0bac29ec92c3f241b.jpg",
          require('../../static/newImg/img98.jpg'),
        ]
      } else if (index == 0) {
        this.imgList2 = [
          require('../../static/newImg/img95.jpg'),
          "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/a0f6fe32e04347b09777330e5dabc153.jpg",
        ]
      } else {
        this.imgList2 = [
          require('../../static/newImg/img99.jpg'),
        ]
      }
    },
    changeImgList1(index) {
      this.container2.slideTo(index);
    },
    getPojectDetail() {
      getPojectDetail(this.projectId).then((res) => {
        this.projectInfo = res.data;
        if (!this.projectInfo.base) {
          this.projectInfo.base = {};
        }
        this.projectInfo.landDTOList.forEach((val) => {
          val.landFirstImage = val.landImage.split(",")[0];
        });
        this.container1 = new Swiper(".swiper-container1", {
          centeredSlides: true,
          observer: true, //开启动态检查器，监测swiper和slide
          observeParents: true, //监测Swiper 的祖/父元素
          loop: true,
        });
        const that = this;
        this.container2 = new Swiper(".swiper-container2", {
          on: {
            slideChangeTransitionEnd: function (swiper) {
              if (swiper.activeIndex == 1) {
                that.imglist1 = [
                  require('../../static/newImg/whq1.jpg'),
                  require('../../static/newImg/whq2.jpg'),
                  require('../../static/newImg/whq3.jpg'),
                  require('../../static/newImg/whq4.jpg'),
                  require('../../static/newImg/whq5.mp4'),
                  require('../../static/newImg/whq6.mp4'),
                  require('../../static/newImg/whq7.jpg'),
                ]
              }
              if (swiper.activeIndex == 0) {
                that.imglist1 = [
                  require('../../static/newImg/zzq1.jpg'),
                  require('../../static/newImg/zzq2.jpg'),
                  require('../../static/newImg/zzq3.jpg'),
                  "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/9ee9e169fb644436b125355220cd671d.jpg",
                  require('../../static/newImg/zzq5.jpg'),
                  require('../../static/newImg/zzq6.jpg'),
                  require('../../static/newImg/zzq7.jpg'),
                  require('../../static/newImg/zzq8.jpg'),
                ]
              }
              if (swiper.activeIndex == 2) {
                that.imglist1 = [
                  'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/f0591e2364d44902b8e9d09484b4090d.jpg',
                ]
              }
            },
          },
          slidesPerView: 3,
          observer: true, //开启动态检查器，监测swiper和slide
          observeParents: true, //监测Swiper 的祖/父元素
          centeredSlides: true,
        });
        this.container2.slideTo(1);
        // 接口中获得数据渲染视频
        // this.setImgAndVideo()
        // 直接获取数据
        // this.initVideo()
        this.$nextTick(() => {
          this.container = new Swiper(".swiper-container", {
            slidesPerView: 4,
            spaceBetween: 0,
          });
        });
      });
    },
    prevOnclick() {
      this.$refs.switchover.prev();
    },
    nextOnclick() {
      this.$refs.switchover.next();
    },
    setImgAndVideo() {
      const val = this.projectInfo.base.resource[this.container1Index];
      this.imgAndVideo = [];
      if (val.images) {
        const images = val.images.split(",");
        images.forEach((url) => {
          this.imgAndVideo.push({ type: "img", url });
        });
      }
      if (val.video) {
        const videos = val.video.split(",");
        videos.forEach((url) => {
          this.imgAndVideo.push({ type: "video", url });
        });
      }
      // imgAndVideo为接口获得 
      this.imglist1.forEach((val, index) => {
        if (val.indexOf('.mp4') > -1) {
          setTimeout(() => {
            this.initVideo(val, index);
          }, 1000);
        }
      });
    },
    initVideo(val, index) {
      this.$nextTick(() => {
        // if (this.deviceType == "IOS") {
        //   const videoPlayer = document.getElementById("videoPlayer" + index)
        //   videoPlayer.addEventListener('play', function () { //播放开始执行的函数
        //     setTimeout(() => {
        //       videoPlayer.load();
        //     }, videoPlayer.duration * 1000 + 500)
        //   });
        // } else {
        this['myPlayer' + index] = this.$video(
          document.getElementById("videoPlayer" + index),
          {
            controls: true,
            autoplay: false,
            preload: "auto",
            controlBar: {
              playToggle: true,
            },
          }
        );
        this['myPlayer' + index].src(this.imglist1[index]); // 重新初始化URL
        this['myPlayer' + index].on('ended', () => {
          // 当播放完成时，执行dispose操作
          this['myPlayer' + index].load();
        });
        if (this.deviceType == "IOS") {
          this['myPlayer' + index].on('play', () => {
            // 当播放完成时，执行dispose操作
            setTimeout(() => {
              this['myPlayer' + index].load();
            }, (7 * 1000 + 200))
            // this['myPlayer' + index].load();
          });
        }
        // }
      });
    },
    changeCarousel(index) {
      if (index == 1) {
        this.$refs.projectCarousel.next();
      } else {
        this.$refs.projectCarousel.prev();
      }
    },
    changeContainer2(index) {
      this.container2.slideTo(index);
      // this.$refs.projectCarousel.setActiveItem(index);
    },
    changeAreaCarousel(index) {
      if (index == 1) {
        this.$refs.areaCarousel.next();
      } else {
        this.$refs.areaCarousel.prev();
      }
    },
    getTrends(size) {
      getTrends({
        size: size,
        current: this.current,
        projectId: this.projectId,
      }).then((res) => {
        res.data.list.forEach((val) => {
          const inspector = val.inspector.split('')
          val.inspector = val.inspector.replace(inspector[1], '*')
          if (val.trendsContent) {
            val.imgList = []
            const img = val.trendsContent.split(',')
            img.forEach((val1) => {
              if (val1) {
                val.imgList.push(val1)
              }
            })
          } else {
            val.imgList = [];
          }
        });
        if (this.trendsList.length == 1) {
          this.trendsList = res.data.list;
        } else {
          this.trendsList = this.trendsList.concat(res.data.list);
        }
        if (!localStorage.getItem('showTrendTips') || localStorage.getItem('showTrendDate') != this.trendsList[0].trendsDate) {
          this.showTrendTips = true
        } else {
          this.showTrendTips = false
        }
        if (res.data.total == this.trendsList.length) {
          this.showMore = false;
        } else {
          this.showMore = true;
        }

      });
    },
    getMore() {
      this.current += 1;
      if (this.trendsList.length == 1) {
        this.current = 1;
      }
      this.showMore = false;
      this.getTrends(5);
    },
    toDetail(item) {
      this.$router.push({
        path: "/landDetail",
        query: { id: item.projectLandId, projectId: item.projectId },
      });
    },
    styidclick(index) {
      this.styId = index;
      // if (this.ImageAndVideo[this.ImageAndVideoIndex].type == "video") {
      //   this.initVideo();
      // } else {
      //   if (this.myPlayer) {
      //     this.myPlayer.dispose();
      //   }
      // }
      // this.container.slideTo(
      //   Math.floor(this.ImageAndVideo.length / 3),
      //   1000,
      //   false
      // );
      // this.scrollToVisible(index);
    },
    ListLandMore() {
      this.$router.push("/projectScheduleList");
    },
    ListLandDetails() {
      this.$router.push("/projectSchedule");
    },
    PublicityDocumentMore() {
      this.$router.push("/PublicityDocumentList");
    },
    PublicityDocumentDetails() {
      this.$router.push("/PublicityDocument");
    },
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime)
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.removeEventListener("scroll", this.changeTips)
    } else if (/(Android)/i.test(navigator.userAgent)) {
      window.removeEventListener("scroll", this.changeTips)
    } else {
      window.removeEventListener("scroll", this.changeTitle)
    }
    document.getElementsByClassName('projectDetail_bottom_title')[0].remove()
  }
};
</script>
<style scoped lang="scss">
.projectDetail {
  min-width: 1280px;
  width: 100%;

  .projectDetail_head {
    height: 2668px;
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/e22440529e2f4c0887e9724ecbc4caba.png") no-repeat;
    background-size: cover;

    .projectDetail_head_content {
      width: 1200px;
      margin: 0 auto;
      padding-top: 150px;
      text-align: center;

      // .projectBrief {
      //   background: url("../../static/newImg/projectDetail_bg2.png") no-repeat;
      //   height: 390px;
      // }
      .projectDetail_head_content_projectName {
        width: 100%;
        font-size: 56px;
        color: #ffffff;
        line-height: 56px;
        letter-spacing: 6px;
      }

      .projectDetail_head_content_projectUnit {
        font-size: 30px;
        color: #ffffff;
        line-height: 56px;
        margin-top: 35px;
      }

      .line {
        width: 800px;
        height: 1px;
        background: #ffffff;
        opacity: 0.24;
        margin: 60px auto;
      }

      .projectDetail_head_content_projectDesc {
        font-size: 24px;
        color: #ffffff;
        line-height: 36px;
        margin-bottom: 80px;
      }

      .projectDetail_head_content_projectPrincipal {
        font-size: 24px;
        color: #ffffff;
        line-height: 31px;
        margin-top: 50px;
      }

      .projectDetail_head_content_resource {
        margin-top: 100px;

        .projectDetail_head_content_resource_head {
          margin-bottom: 73px;

          .swiper-container2 {
            width: 780px;
            margin: 0 auto;
            overflow: hidden;

            .swiper-slide-next,
            .swiper-slide-prev {
              font-size: 40px;
              letter-spacing: 1px;
              text-shadow: 8px 13px 12px rgba(0, 0, 0, 0.31);
            }
          }

          .projectDetail_head_content_resource_head_slide {
            font-size: 54px;
            color: #ffffff;
            line-height: 48px;
            box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.4);
            letter-spacing: 2px;

            .swiper-slide-next,
            .swiper-slide-prev {
              font-size: 40px;
              letter-spacing: 1px;
              text-shadow: 8px 13px 12px rgba(0, 0, 0, 0.31);
            }
          }
        }
      }

      .projectDetail_head_content_list {
        margin-top: 100px;

        .projectDetail_head_content_title {
          font-size: 36px;
          color: #ffffff;
          line-height: 43px;
          letter-spacing: 2px;

          .projectDetail_head_content_title_left,
          .projectDetail_head_content_title_right {
            font-size: 12px;
            display: inline-block;
            vertical-align: middle;
          }
        }

        .projectDetail_head_content_list_project {
          margin-top: 40px;
          display: flex;
          justify-content: center;

          .projectDetail_head_content_list_project_item {
            width: 720px;

            .swiper-container1 {
              width: 100%;
              height: 100%;
              overflow: hidden;

              .swiper-slide {
                overflow: hidden;
              }

              .swiper-slide-next {
                font-weight: bold;
              }
            }

            .projectDetail_head_content_list_project_item_img {
              width: 720px;
              overflow: hidden;

              img {
                display: block;
              }
            }

            .projectDetail_head_content_list_project_item_content {
              overflow: hidden;
              width: 640px;
              height: 370px;
              background: rgba(255, 255, 255, 0.06);
              border-radius: 7px;
              padding: 40px;
              text-align: left;

              .projectDetail_head_content_list_project_item_content_projectProductName {
                font-size: 32px;
                color: #ffffff;
                line-height: 32px;
                letter-spacing: 1px;
                display: inline-block;
                vertical-align: bottom;
              }

              .projectDetail_head_content_list_project_item_content_projectProductCode {
                font-size: 24px;
                color: #ffffff;
                letter-spacing: 1px;
                display: inline-block;
                margin-left: 25px;
              }

              .projectDetail_head_content_list_project_item_content_projectProductDesc {
                font-size: 18px;
                font-weight: 400;
                color: #ffffff;
                line-height: 34px;
                letter-spacing: 1px;
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }

  .projectDetail_top {
    height: 1032px;
    width: 100%;
    text-align: center;
    position: relative;

    .projectDetail_top_bg1 {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to right, rgba(19, 61, 24, .95) 0%, rgba(30, 97, 25, .88) 100%);
      // background: url("../../static/newImg/top_imar1.jpg") no-repeat;
      background-size: cover;
      z-index: 2;
    }

    .projectDetail_top_bg2 {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/d81e749bb09642c29f91750fa6a318cd.png") no-repeat;
      background-size: cover;
      z-index: 1;
    }

    .projectDetail_top_area {
      z-index: 3;
      position: absolute;
      top: 48%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 56px;
      color: #ffffff;
      letter-spacing: 2px;

      .projectDetail_top_projectName {
        width: 1280px;
        height: 60px;
        margin: 0 auto;
        font-size: 60px;
        font-weight: bold;
        letter-spacing: 2px;
        font-family: 楷体;
        display: flex;
        justify-content: center;
      }

      .projectDetail_top_area_projectPrincipal {
        font-size: 22px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        letter-spacing: 1px;

        .duangang {
          margin: 0 25px;
        }

        img {
          margin: 5px;
          margin-bottom: 0;
          margin-right: 10px;
          width: 28px;
          height: 34px;
        }

        a {
          color: #1e6314;
          cursor: pointer;
        }
      }

      .projectDetail_top_area_line {
        width: 1200px;
        height: 1px;
        background: #FFFFFF;
        opacity: 0.13;
        margin: 40px auto;
      }
    }

    .projectDetail_bottom_title {
      position: absolute;
      bottom: 0 !important;
      left: 0;
      width: 100%;
      height: 70px;
      background: rgba(108, 149, 85, .52);
      z-index: 3;

      .projectDetail_bottom_title_outSide {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;

        .projectDetail_bottom_title_item {
          font-size: 24px;
          color: #FFFFFF;
          line-height: 70px;
          width: 240px;
          cursor: pointer;
          position: relative;

          .projectDetail_bottom_title_item_new {
            position: absolute;
            top: 15px;
            right: 45px;
          }
        }

        .projectDetail_bottom_title_item:hover {
          background-image: url("../../static/newImg/projectDetail_bottom_title_outSide_bg1.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .active {
          background-image: url("../../static/newImg/projectDetail_bottom_title_outSide_bg.png") !important;
          background-repeat: no-repeat;
          background-size: cover !important;
          background-position: center;
          color: #354A32;
        }
      }

    }

    .projectDetail_top_area_unit {
      margin-top: 60px;
      font-size: 22px;
      letter-spacing: 1px;
    }

    .projectDetail_top_area_projectDesc {
      width: 1000px;
      margin: 0 auto;
      font-size: 22px;
      color: #FFFFFF;
      text-align: justify;
    }

    .projectDetail_top_area_download {
      display: flex;
      justify-content: space-evenly;
      width: 810px;
      margin: 77px auto;
    }
  }

  .projectDetail_top_projectBrief {
    height: 500px;
    background: url("../../static/newImg/projectDetail_bg2.png") no-repeat;
    background-size: cover;
    padding: 100px 0;
    text-align: center;

    .projectDetail_top_area {
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      color: #000;
      line-height: 43px;
      letter-spacing: 2px;
      margin-bottom: 100px;
    }

    .projectDetail_top_area_unit {
      text-indent: 2em;
      text-align: justify;
      height: 225px;
      width: 1140px;
      font-weight: 400;
      margin: 0 auto;
      margin-top: 44px;
      margin-bottom: 100px;
      font-size: 30px;
    }
  }

  .projectDetail_productPresentation_projectBrief {
    height: 820px;
    color: #fff;
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/357d197f8b2349f8b17becb4ce00d5c6.png") no-repeat;
    background-size: cover;
    position: relative;

    .projectDetail_productPresentation_projectBrief_content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1200px;
      margin: 0 auto;
      text-align: center;

      .projectDetail_productPresentation_projectBrief_content_outside {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .projectDetail_productPresentation_projectBrief_content_left,
      .projectDetail_productPresentation_projectBrief_content_right {
        cursor: pointer;
        opacity: 0.8;
      }

      .projectDetail_productPresentation_projectBrief_content_list {
        overflow: hidden;
        width: 1000px;
        position: relative;

        .productList_bg {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 90px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(1, 28, 8, 0.69) 100%);
          z-index: 9;

          .productList_bg_item {
            display: inline-block;
            padding: 12px 4px;
            background-color: transparent;
            cursor: pointer;

            .productList_bg_item_point {
              display: block;
              opacity: .48;
              background-color: #FFF;
              border: none;
              outline: 0;
              padding: 0;
              margin: 0;
              cursor: pointer;
              transition: .3s;
              width: 10px;
              height: 10px;
              border-radius: 25px;
            }
          }

          .active {

            .productList_bg_item_point {
              width: 20px;
              opacity: 1;
            }
          }

        }


        .productPresentation_top_area_left,
        .productPresentation_top_area_right {
          border-radius: 50%;
          width: 86px;
          height: 86px;
          background: #FFFFFF;
          color: #000;
          line-height: 86px;
          font-size: 36px;
          opacity: 0.47;
        }
      }

    }

    .projectDetail_productPresentation_projectBrief_list {
      display: flex;

      .projectDetail_productPresentation_projectBrief_list_item_img {
        width: 60%;
        height: 400px;
        overflow: hidden;
        position: relative;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        img {
          display: block;
        }

        .projectDetail_productPresentation_projectBrief_list_item_img_tips {
          position: absolute;
          top: 25px;
          left: 30px;
          width: 126px;
          height: 40px;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 24px;
          font-weight: bold;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
        }
      }

      .projectDetail_productPresentation_projectBrief_list_item_intro {
        width: 40%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 340px;
        background: rgba(255, 255, 255, 0.06);
        padding: 30px;
        text-align: left;

        .projectDetail_productPresentation_projectBrief_list_item_intro_title {
          font-size: 32px;
          color: #FFFFFF;
          line-height: 21px;
          letter-spacing: 1px;
          margin-top: 30px;
        }

        .projectDetail_productPresentation_projectBrief_list_item_intro_tips {
          font-size: 16px;
          margin-top: 28px;
          line-height: 30px;
        }
      }
    }

    .productPresentation_top_area {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 100px;
    }

    .productPresentation_head_content_title_left,
    .productPresentation_head_content_title_right {
      font-size: 12px;
      width: 22px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
    }

    .carousel_right {
      // flex: 0.9;
      // z-index: -1;
      margin: 25px 0;
      padding: 34px;
      width: 520px;
      // height: 316px;
      background: #ffffff;
      border-radius: 7px;
      background: rgba(255, 255, 255, 0.06);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      img {
        width: 17px;
        height: 19px;
      }
    }

    .carousel_left {
      height: 366px;
      position: relative;
    }

    .right_img {
      z-index: 12;
      transform: rotate(0.5turn);
      transform: rotate(0.5turn);
      position: absolute;
      width: 60px;
      height: 60px;
      cursor: pointer;
      line-height: 61px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      left: -32px;
      top: calc(50% - 30px);

      img {
        width: 17px;
        height: 19px;
      }
    }

    .left_img {
      z-index: 12;
      position: absolute;
      width: 60px;
      height: 60px;
      cursor: pointer;
      line-height: 67px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      right: -32px;
      top: calc(50% - 30px);

      img {
        width: 17px;
        height: 19px;
      }
    }
  }

  .projectDetail_area {
    height: 1200px;
    background: url("../../static/newImg/projectDetail_bg4.png") no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;

    .projectDetail_area_contentDiv {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1200px;

      .projectDetail_area_contentDiv_outside {
        border-radius: 20px;
        width: 1200px;
        margin: 50px auto 30px auto;
        height: 776px;
        overflow: hidden;
        box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.4);
        position: relative;

        .projectDetail_area_contentDiv_outside_bg {
          position: absolute;
          bottom: 192px;
          left: 0;
          width: 100%;
          height: 90px;
          background: linear-gradient(180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(1, 28, 8, 0.69) 100%);
          z-index: 9;
        }

        ::v-deep .el-carousel__indicators {
          bottom: 220px;
        }

        .projectDetail_area_contentDiv_outside_tips {
          position: absolute;
          bottom: 0;
          left: 0;
          width: calc(100% - 60px);
          height: 132px;
          background: rgba(1, 18, 6, .8);
          padding: 30px;
          z-index: 9;

          .projectDetail_area_contentDiv_outside_tips_title {
            font-weight: 500;
            font-size: 24px;
            color: #FFFFFF;
            text-align: left;
          }

          .projectDetail_area_contentDiv_outside_tips_collect {
            display: flex;
            margin-top: 20px;

            .projectDetail_area_contentDiv_outside_tips_collect_htnx,
            .projectDetail_area_contentDiv_outside_tips_collect_csrq {
              width: 40%;
              font-weight: 400;
              font-size: 18px;
              color: #FFFFFF;
              text-align: left;
              height: 70px;
              line-height: 35px;
            }

            .projectDetail_area_contentDiv_outside_tips_collect_more {
              width: 20%;
              text-align: right;
            }
          }
        }
      }

      .projectDetail_area_contentDiv_list {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;

        .projectDetail_area_contentDiv_list_item {
          width: 340px !important;
          text-align: center;
          overflow: hidden;

          .projectDetail_area_contentDiv_list_item_outside {
            overflow: hidden;
            border: 8px solid transparent;
            height: 150px;
            position: relative;
          }

          .projectDetail_area_contentDiv_list_item_tips {
            font-size: 20px;
            color: #354A32;
            text-align: center;
            margin-top: 30px;
          }

          img {
            width: 385px;
            display: block;
          }
        }

        .active {
          border: 8px solid #26770C !important;
        }
      }
    }

    .projectDetail_area_title {
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      color: #354A32;
      line-height: 43px;

      .projectDetail_area_title_left,
      .projectDetail_area_title_right {
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .projectDetail_area_content_left {
      display: inline-block;
      transform: rotate(180deg);
      cursor: pointer;
    }

    .projectDetail_area_content_right {
      display: inline-block;
      cursor: pointer;
    }

    .projectDetail_area_content {
      width: 1200px;
      padding: 0 50px;
      height: 506px;
      margin: 0 auto;
      vertical-align: middle;
      display: inline-block;

      .projectDetail_area_content_item {
        display: flex;
        cursor: pointer;
        justify-content: center;

        .projectDetail_area_content_item_img {
          overflow: hidden;
          position: relative;

          .R_btn {
            position: absolute;
            width: 100px;
            line-height: 40px;
            top: 20px;
            left: 20px;
            height: 40px;
            background: #000000;
            border-radius: 24px;
          }
        }

        .projectDetail_area_content_item_right {
          width: 480px;
          background: #ffffff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
          padding: 40px 0 34px 36px;
          font-size: 18px;
          // width: 480px;
          font-weight: bold;
          color: #354a32;
          line-height: 20px;
          text-align: left;

          .ispan {
            display: inline-block;
            margin-bottom: 20px;
          }

          .bbtn {
            width: 124px;
            height: 42px;
            background: #354a32;
            border-radius: 21px;
            margin-top: 45px;
          }

          .whippletree {
            width: 90%;
            height: 1px;
            margin-bottom: 20px;
            background: rgb(53, 74, 50);
            opacity: 0.22;
          }

          div {
            display: inline-block;
            width: 90px;
          }

          .projectDetail_area_content_item_right_label {
            // width: 320px;
            // width: 100%;
            display: inline-block;
            font-size: 18px;
            font-weight: 400;
            color: #6b766a;
            line-height: 20px;
            margin-top: 24px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .projectDetail_dynamic {
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/d9fedc4583854c0f9ac650666b6befb3.jpg") no-repeat;
    background-size: 100%;
    padding: 150px 0;

    .projectDetail_dynamic_title {
      text-align: center;
      font-size: 36px;
      color: #354a32;
      font-weight: bold;
      line-height: 43px;
      margin-bottom: 100px;

      .projectDetail_dynamic_title_left,
      .projectDetail_dynamic_title_right {
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .projectDetail_dynamic_list {
      width: 1200px;
      margin: 0 auto;
      text-align: left;

      .projectDetail_dynamic_list_time {
        font-size: 40px;
        font-weight: bold;
        color: #252525;
        line-height: 62px;
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;
      }

      .projectDetail_dynamic_list_line {
        margin: 0 29px;
        border-left: 4px solid #f5f5f7;

        .projectDetail_dynamic_list_line_inside {
          padding: 40px 60px;
          border-radius: 27px;

          .projectDetail_dynamic_list_line_bg {
            background: linear-gradient(180deg, #f5f5f7 0%, #ffffff 100%);
            width: calc(100% - 80px);
            border-radius: 27px;
            padding: 40px;

            .projectDetail_dynamic_list_line_name {
              font-size: 40px;
              font-weight: bold;
              color: #252525;
              line-height: 36px;
            }

            .projectDetail_dynamic_list_line_content {
              font-size: 32px;
              font-weight: 500;
              color: #595959;
              line-height: 30px;
              margin: 40px 0;
            }

            .projectDetail_dynamic_list_line_img {
              display: flex;
              flex-wrap: wrap;

              .el-image {
                width: 31%;
                height: 266px;
                margin: 1% 0 0 1%;
              }
            }

            .projectDetail_dynamic_list_line_tips {
              width: 107px;
              height: 34px;
              background: #ba883f;
              border-radius: 4px;
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
              line-height: 34px;
              letter-spacing: 1px;
              margin-top: 42px;
              text-align: center;
            }
          }
        }
      }

      .projectDetail_dynamic_list_more {
        width: 1000px;
        height: 120px;
        background: #f5f5f7;
        border-radius: 13px;
        text-align: center;
        font-size: 32px;
        font-weight: 400;
        color: #252525;
        line-height: 120px;
        margin-left: 96px;
        cursor: pointer;
      }
    }
  }

  .projectDetail_productPresentation_base {
    height: 1400px;
    color: #fff;
    background-size: cover;
    text-align: center;
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/9a33f54ac25941d1990de398ccafb302.jpg") no-repeat;
    background-size: cover;
    position: relative;

    .projectDetail_productPresentation_base_content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1200px;
    }

    .productPresentation_top_area {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 100px;
    }

    .productPresentation_head_content_title_left,
    .productPresentation_head_content_title_right {
      font-size: 12px;
      width: 22px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
    }

    .ProjectBase {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      font-size: 22px;
      color: #FBFFF0;

      .ProjectBase_punctuation {
        font-size: 20px;
        margin: 0 30px;
      }
    }

    .ProjectBase1 {
      display: flex;
      justify-content: center;
      font-size: 22px;
      height: 167px;
      background-image: url("../../static/newImg/tripartite_bg.png");
      background-repeat: no-repeat;
      background-size: 100% 167px;
      color: #FBFFF0;

      div {
        text-align: left;
      }

      .ProjectBase1_btn {
        height: 34px;
        padding: 0 9px;
        background: linear-gradient(128deg, #FFF3A0 0%, #E5A449 57%, #F2BA55 100%);
        border-radius: 6px;
        font-size: 14px;
        color: #412D10;
        line-height: 34px;
        display: inline-block;
        cursor: pointer;
      }

    }

    .el-carousel_head_content_title_left {
      margin-top: 30px;

      ::v-deep .el-carousel__indicators {
        bottom: 60px;
      }

      .carousel_left {
        width: 100%;
        overflow: hidden;
        border-radius: 20px;
        box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.4);
        position: relative;

        .carousel_left_bg {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 150px;
          background: linear-gradient(180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.78) 100%);
        }
      }
    }

    .mask {
      width: 640px;
      background: #000;
      height: 60px;
      position: absolute;
      bottom: 0px;
      opacity: 0.6;
    }

    .projectDetail_head_content_resource_head {
      margin-top: 100px;

      // margin-bottom: 73px;
      .swiper-container2 {
        width: 800px;
        margin: 0 auto;
        height: 70px;
        overflow: hidden;

        .swiper-slide-next,
        .swiper-slide-prev {
          font-size: 32px;
          opacity: 0.5;
          letter-spacing: 1px;
        }
      }

      .swiper-slide {
        opacity: .15;
        font-size: 40px !important;
      }

      .swiper-slide-active {
        opacity: 1;
        font-size: 54px !important;
      }

      .projectDetail_head_content_resource_head_slide {
        // font-size: 44px;
        color: #ffffff;
        line-height: 48px;
        letter-spacing: 2px;

        .swiper-slide-next,
        .swiper-slide-prev {
          font-size: 40px;
          letter-spacing: 1px;
          text-shadow: 8px 13px 12px rgba(0, 0, 0, 0.31);
        }
      }
    }
  }

  .projectDetail_productPresentation_Schedule {
    height: 1200px;
    background-size: cover;
    text-align: center;
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/acb390c7d99347e0b8e60d9644de07b9.png") no-repeat;
    background-size: cover;
    position: relative;

    .productPresentation_top_area_Schedule {
      font-size: 36px;
      width: 1200px;
      margin: 0 auto;
      font-weight: bold;
      color: #FFFFFF;
      padding-top: 137px;
    }

    .projectDetail_productPresentation_Schedule_content {
      overflow: hidden;
    }

    .projectDetail_productPresentation_Schedule_content_line {
      margin: 200px 0 50px 0;
      position: relative;

      .swiper-container3 {
        width: 1200px;
        position: absolute;
        top: -109px;
        left: 50%;
        transform: translate(-50%, 0);
      }

      .projectDetail_productPresentation_Schedule_content_line_1 {
        background-color: #6B766A;
        height: 2px;
        width: 100%;
      }

      .projectDetail_productPresentation_Schedule_content_line_item {
        // width: 310px !important;
        font-size: 22px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;

        .projectDetail_productPresentation_Schedule_content_line_item_date {
          margin-top: 10px;
          opacity: .2;
        }

        .projectDetail_productPresentation_Schedule_content_line_item_activeIcon {
          display: none;
        }

        .projectDetail_productPresentation_Schedule_content_line_item_circle {
          width: 20px;
          height: 20px;
          background: #284822;
          border-radius: 50%;
          border: 4px solid #6b766a5b;
          margin: 17px auto 0 auto;
        }

        .projectDetail_productPresentation_Schedule_content_line_item_name {
          margin-top: 24px;
          font-size: 20px;
          opacity: .2;
        }

        .projectDetail_productPresentation_Schedule_content_line_item_icon {
          margin-top: 15px;
          opacity: .2;
        }
      }



      .active {
        div {
          opacity: 1 !important;
        }

        .projectDetail_productPresentation_Schedule_content_line_item_circle {
          border: 4px solid #FFFFFF;
        }

        .projectDetail_productPresentation_Schedule_content_line_item_activeIcon {
          display: block;
          color: #FFFFFF;
          font-weight: bold;
          margin-top: 10px;
        }

        .projectDetail_productPresentation_Schedule_content_line_item_name {
          margin-top: 10px;
        }
      }

    }

    .projectDetail_productPresentation_Schedule_content_outside {
      width: 1200px;
      margin: 150px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .projectDetail_productPresentation_Schedule_content_left,
      .projectDetail_productPresentation_Schedule_content_right {
        cursor: pointer;
        opacity: .8;
      }

      .showBtn {
        opacity: 0;
      }
    }

    .projectDetail_productPresentation_Schedule_content_list {
      width: 900px;
      height: 326px;
      padding: 50px;
      background: #FFFFFF;
      border-radius: 8px;
      display: flex;
      justify-content: space-around;
      transition: all 0.2s linear;

      img {
        border-radius: 8px;
      }

      .projectDetail_productPresentation_Schedule_content_list_img {
        width: 500px;
        height: 326px;
        overflow: hidden;
      }

      .projectDetail_productPresentation_Schedule_content_list_content {
        width: 370px;
        position: relative;

        .projectDetail_productPresentation_Schedule_content_list_content_nav {
          width: 40px;
          height: 4px;
          background: #354A32;
          border-radius: 3px;
        }

        .projectDetail_productPresentation_Schedule_content_list_content_tips {
          margin-top: 10px;
          font-weight: 500;
          font-size: 22px;
          color: #565759;
          line-height: 32px;
          letter-spacing: 1px;
          text-align: left;
        }

        .projectDetail_productPresentation_Schedule_content_list_content_file {
          font-size: 16px;
          margin-top: 25px;
          color: #19812c;
          cursor: pointer;
          text-align: left;
          margin-top: 30px;
          cursor: pointer;
        }

        .projectDetail_productPresentation_Schedule_content_list_content_date {
          font-weight: 400;
          font-size: 20px;
          color: #565759;
          line-height: 30px;
          letter-spacing: 1px;
          text-align: left;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        .projectDetail_productPresentation_Schedule_content_list_content_more {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 62px;
          height: 62px;
          line-height: 62px;
          border-radius: 50%;
          font-size: 24px;
          background: rgba(0, 0, 0, 0.25);
          color: #fff;
        }
      }
    }

    .moveleft {
      transform: translateY(20px);
      opacity: 0.7;
    }

    .projectDetail_productPresentation_Schedule_content_more {
      margin: 138px auto;
      width: 260px;
      height: 68px;
      background: #354A32;
      border-radius: 34px;
      font-weight: 500;
      font-size: 23px;
      color: #FFFFFF;
      line-height: 68px;
    }

    .productPresentation_head_content_title_left,
    .productPresentation_head_content_title_right {
      width: 22px;
      height: 20px;
      font-size: 12px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}


.site_dialog_outside {
  overflow: hidden;

  .site_Dialog {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 20px;

    .site_Dialog_item {
      width: 100%;
      font-size: 16px;
      color: #595959;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #F5F5F7;
      display: flex;

      .site_Dialog_item_img {
        width: 30%;
        overflow: hidden;
      }

      .site_Dialog_item_title {
        width: calc(70%);
        margin-top: 8px;

        .site_Dialog_item_title_label {
          margin-bottom: 12px;
          font-size: 18px;
          color: #252525;

          span {
            display: inline-block;
            width: 100px;
            color: #787878;
          }
        }

        .site_Dialog_item_title_label:last-child {
          margin-bottom: 0;
        }
      }
    }

    .site_Dialog_item:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

::v-deep .el-dialog {
  transform: translate(0, -50%);
}

.report_dialog_outside {
  overflow: hidden;

  .report_Dialog {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 20px;

    .report_Dialog_item {
      width: 100%;
      height: 200px;
      font-size: 16px;
      color: #595959;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #F5F5F7;
      display: flex;

      .report_Dialog_item_img {
        height: 200px;
        width: 300px;
        overflow: hidden;
      }

      .report_Dialog_item_title {
        width: calc(70% - 30px);
        margin-left: 30px;

        .report_Dialog_item_title_label {
          margin-bottom: 12px;
          font-size: 16px;
          color: #252525;

          span {
            display: inline-block;
            width: 80px;
            color: #787878;
          }
        }

        .report_Dialog_item_title_more {
          font-size: 16px;
          margin-top: 25px;
          color: #19812c;
          cursor: pointer;
        }
      }
    }

    .report_Dialog_item:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.el-carousel_head_content_title_left {
  margin-top: 117px;
}

.el-carousel_head_content_title_left_son {
  width: 1200px;
}

.el-carousel__item h3 {
  color: red;
  font-size: 18px;
  opacity: 0.75;
  line-height: 30px;
  margin: 0;
}

::v-deep .el-carousel__arrow {
  display: none;
}

/* 修改指示器的背景色和边框 */
.el-carousel__indicator {
  background-color: #999;
  border: none;
}

/* 修改激活状态下的指示器样式 */
.el-carousel__indicator.is-active {
  background-color: #333;
}

/* 如果指示器位置在外部，还需要调整外部指示器容器的样式 */
.el-carousel__indicators--outside {
  bottom: 10px;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 25px;
}

::v-deep .el-carousel__indicator.is-active button {
  width: 20px;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 10px;
}

::v-deep .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 25px;
}

::v-deep .el-dialog__headerbtn {
  font-size: 22px;
}

::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #155512 !important;
}

::v-deep .video-js {
  width: 100% !important;
  height: 100% !important;
  background-color: #000;

  .vjs-big-play-button {
    width: 100px;
    height: 100px;
    border: 0;
    background-color: rgba(0, 0, 0, 0.67);
    border-radius: 50%;
    line-height: 100px;
    font-size: 80px;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}

.container {
  display: flex;
  overflow-x: hidden;
  width: 660px;

  .inner-container {
    flex-shrink: 0;
    display: flex;
  }

  .selected {
    border: 4px solid #3bac30;
  }
}

.landDetail_head_left_list {
  display: flex;
  // margin-top: 32px;
  width: 660px;

  .landDetail_head_left_list_item {
    width: 130px !important;
    text-align: center;
    border: 3px solid #fff;
    height: 110px;
    overflow: hidden;

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .active {
    border: 4px solid #3bac30;
  }
}

.text-container {
  position: relative;
  line-height: 1.5;
  max-height: 4.5em;
  overflow: hidden;
  margin-left: 32px;
  margin-top: 12px;
  width: 320px;
  height: 81px;
}

.text-container::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
  // background: linear-gradient(to right, transparent, white 100%);
  background: white;
  padding-left: 4px;
}

.projectDetail_bottom_title_outSide {

  .active {
    background-image: url("../../static/newImg/projectDetail_bottom_title_outSide_bg.png") !important;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center;
    color: #354A32;
  }
}
</style>
